jQuery(function ($) {

  $('.mw_wp_form_confirm .contactPolicy').remove();


  $('.zip-button').click(function () {
    AjaxZip3.zip2addr('zip1', 'zip2', 'addr1', 'addr1');
  });

});
