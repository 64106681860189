//Swiper Top
var swiperKv = new Swiper('.swiperKv', {
  speed: 750,
  autoplay: {
    delay: 4000,
  },
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

// Top バナー
var swiperSlide01 = new Swiper('.swiperSlide01', {
  autoplay: {
    delay: 3000,
  },
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next01',
    prevEl: '.swiper-button-prev01',
  },
  breakpoints: {
    1024: {
      slidesPerView: 2,
      spaceBetween: 10
    },
  }
});

// Hospital list
var swiperSlide02 = new Swiper('.swiperSlide02', {
  autoplay: {
    delay: 3000,
  },
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next02',
    prevEl: '.swiper-button-prev02',
  },
  breakpoints: {
    1024: {
      slidesPerView: 1.5,
      centeredSlides: true,
      spaceBetween: 10,
    },
  },
});


$('.swiper-slide.map').click(function () {
  const num = $(this).data('num');
  const windowWidth = $(window).width();
  if (windowWidth < 1024) {
    let num2 = num - 1;
    swiperSlide02.slideTo(num2);
  } else {
    swiperSlide02.slideTo(num);
  }
});


// 病院TOP
var swiperSlide03 = new Swiper('.swiperSlide03', {
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  loop: true,
  speed: 2000,
  effect: 'fade',
});





// Hospital list Map
if ($('.hospitalSlider').length) {

  var goCheck = function () {

    var result = $('.swiperSlide02 .swiper-wrapper').find('.swiper-slide-active');

    var result = result.attr('class');

    var result = result.split(' ');

    var result = result[1];

    return result;
  }


  $(function () {
    setInterval(function () {
      goCheck();
      var hospitalNumber = this.goCheck();
      var hospitalActive = document.querySelector(`#${hospitalNumber}`)
      $('.swiper-slide').removeClass('is_hospital');
      $(hospitalActive).addClass('is_hospital');
    }, 100)
  })

}
