jQuery(function ($) {






  // ナビゲーションのリンクを指定
  var navLink = $('.hospitalNav__link');
  var contentsArr = new Array();

  function getHeight() {
    // 各コンテンツのページ上部からの開始位置と終了位置を配列に格納しておく
    for (var i = 0; i < navLink.length; i++) {
      // コンテンツのIDを取得
      var targetContents = navLink.eq(i).attr('href');
      // ページ内リンクでないナビゲーションが含まれている場合は除外する
      if (targetContents.charAt(0) == '#') {
        // ページ上部からコンテンツの開始位置までの距離を取得
        var targetContentsTop = $(targetContents).offset().top - 300;
        // ページ上部からコンテンツの終了位置までの距離を取得
        var targetContentsBottom = targetContentsTop + $(targetContents).outerHeight(true) - 1;
        // 配列に格納
        contentsArr[i] = [targetContentsTop, targetContentsBottom]
      }
    };
  }
  // 現在地をチェックする
  function currentCheck() {
    // 現在のスクロール位置を取得
    var windowScrolltop = $(window).scrollTop();
    for (var i = 0; i < contentsArr.length; i++) {
      // 現在のスクロール位置が、配列に格納した開始位置と終了位置の間にあるものを調べる
      if (contentsArr[i][0] <= windowScrolltop && contentsArr[i][1] >= windowScrolltop) {
        // 開始位置と終了位置の間にある場合、ナビゲーションにclass="current"をつける
        navLink.removeClass('is_current');
        navLink.eq(i).addClass('is_current');
        i == contentsArr.length;
      }
    };
  }

  const isHospitalTopPage = $('.l-hospital .swiperSlide03, .l-page-sidebar')

  if (isHospitalTopPage.length) {
    // ページ読み込み時とスクロール時に、現在地をチェックする
    $(window).on('load', function () {
      getHeight();
    });
    $(window).on('scroll', function () {
      currentCheck();
    });
    $('#priceBtn').click(function () {
      setTimeout(function () {
        getHeight();
      }, 500)
    });
  }


});
