// jQuery start
jQuery(function ($) {

  // Smooth scroll
  const smoothScroll = () => {
    $('a[href^="#"]').click(function () {
      var headerFixedHight = 80;
      var speed = 800;
      var href = jQuery(this).attr("href");
      var target = jQuery(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top - headerFixedHight;
      jQuery("html, body").animate({
        scrollTop: position
      }, speed, "swing");
      return false;
    });
  }
  smoothScroll();

  // loading
  const loading = () => {
    $(document).ready(function () {
      jQuery(".loading").addClass("active");
    });
  }
  // loading();


  // SP Button
  $('.spMenu__btn-menu').click(function () {
    $('.l-header').toggleClass('open');
  });
  $('.gnavBtn-close').click(function () {
    $('.l-header').removeClass('open');
  });

  // SP　アコーディオンメニュー
  $('.gnav__link.icoPlus').click(function () {
    $(this).toggleClass('open');
    $(this).next('ul').slideToggle();
  });
  $('.l-hospital .icoArrow').click(function () {
    $('.l-hospital-header').removeClass('open');
  });

  // Faq アコーディオン
  $('.pageFaq__q').click(function () {
    $(this).toggleClass('open');
    $(this).next('.pageFaq__a').slideToggle();
  });


  // PC Gnav Mouseover
  if ($(window).width() >= 1024) {

    // PC Header
    var myH = $(window).height();
    $(window).on('scroll', function () {
      if ($(window).scrollTop() > 25) {
        $('.header__inner').addClass('fixed');
      } else {
        $('.header__inner').removeClass('fixed');
      }
    })


    // PC Gnav
    if ($('.gnav__item').length) {
      var headerInner = $('.header__inner');
      var bgGray = $('.bg-gray-translucent');
      $('.gnav__item:has("ul")').hover(function () {
        var megaMenu = $(this).children('.gnavChild__list');
        TweenMax.to(megaMenu, 0, {
          opacity: 1,
          visibility: "visible",
          ease: Power3.easeOut
        });
        bgGray.addClass('is_active');
        headerInner.addClass('is_hover');
      }, function () {
        TweenMax.to($('.gnavChild__list'), 0, {
          opacity: 0,
          visibility: "hidden",
          ease: Power3.easeOut
        }, 4);
        bgGray.removeClass('is_active');
        headerInner.removeClass('is_hover');
      });
    }
  }


  // アコーディオン
  $('.js-accordion').click(function () {
    $('.l-sec-price').toggleClass('open');
    $(this).toggleClass('open');
    $(this).next().slideToggle('slow');
  });

  // メッセージの続きを見る
  $('.js-accordion-message').click(function () {
    var cardMessageBody = $(".cardMessage__body");

    $(this).toggleClass('open');
    $(this).prev().slideToggle('slow');
    if ($(this).hasClass('open')) {
      $(this).click(function () {
        $('html, body').animate({
          scrollTop: cardMessageBody.offset().top
        }, 500);
      })
    }
  });

  // 病院を探す select option
  $('.btn-select').change(function () {
    var selectNumber = $(this).val();
    $('.hospitalSearchItem').hide();
    $(`.${selectNumber}`).slideToggle('slow');
  })

  // pageTop
  var pageTop = $('#pageTop');
  $(window).scroll(function () {
    if ($(this).scrollTop() > 200) {
      pageTop.addClass('is_show');
    } else {
      pageTop.removeClass('is_show');
    }
  });

  // h-spMenu fixed
  var spMenuBtn = $('.h-spMenu__btn');
  var gnavBtnClose = $('.gnavBtn-close')
  var gnavLink = $('.gnav__link')
  var spMenu = $('.h-spMenu');
  var logo = $('.l-hospital-header__logo_wrap');
  if (spMenu.length) {
    var offsetTop = spMenu.offset().top;
  }

  var pos = 0;
  $(window).on('scroll', function () {
    if ($(this).scrollTop() < 200) {
      spMenu.removeClass('move');
    } else if ($(this).scrollTop() < pos) {
      spMenu.removeClass('move');
    } else {
      spMenu.addClass('move');
    }
    pos = $(this).scrollTop();
  });

  $(window).on('scroll', function () {
    var doch = $(document).innerHeight();
    var winh = $(window).innerHeight();
    var bottom = doch - winh;
    if (bottom * .97 <= $(window).scrollTop()) {
      spMenu.addClass('move');
    }
  });

  spMenuBtn.click(function () {
    spMenu.hide();
    logo.hide();
  });

  gnavBtnClose.click(function () {
    setTimeout(function () {
      spMenu.show();
      logo.show();
    }, 150)
  });

  gnavLink.click(function () {
    setTimeout(function () {
      spMenu.show();
      logo.show();
    }, 800)
  });


  // l-page-sidebar Fixed
  var lPageSidebar = $('.l-page-sidebar');


  if (lPageSidebar.length) {

    $(window).scroll(function () {
      if ($(this).scrollTop() > 372) {
        lPageSidebar.addClass('fixed')
      } else {
        lPageSidebar.removeClass('fixed');
      }
    });
  }




});
