jQuery(function ($) {

  const lHospitalSidebar = $('.vpc.l-hospital-sidebar')


  const lHospitalSidebarAnimation = () => {
    $(window).on('scroll', function () {

      //ページ全体の高さ
      const docHeight = $(document).innerHeight();

      //ウィンドウの高さ
      const winHeight = $(window).innerHeight();

      // Footerの高さ
      const footerHeight = $('.l-footer').innerHeight();

      //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
      const bottomHeight = docHeight - winHeight;

      // classを追加したいタイミングを設定
      const addClass = docHeight - winHeight - footerHeight * 2 ;

      if (addClass <= $(window).scrollTop()) {
        //addClassまでスクロールした時の処理
        TweenMax.to(lHospitalSidebar, 0.2, {
          autoAlpha: 0,
          ease: Power2.easeOut,
        });
      } else{
        TweenMax.to(lHospitalSidebar, 0.2, {
          autoAlpha: 1,
          ease: Power2.easeOut,
        });
      }

    });
  }


  if (lHospitalSidebar.length) {
    lHospitalSidebarAnimation();
  }


});

